import { routerHistory } from "../app"
import { LANGUAGES, COUNTRIES } from "../../shared/constants"
import { Select, AutoComplete } from "antd"
import React, { useEffect, useState } from "react"
import { SearchMagnifier, Cross } from "../../shared/icons"
import { Header } from "../../shared/components/header"
import { Footer } from "../../shared/components/footer"
// import { Search } from "../search"
import { Grid } from "../grid"
import { useParams } from "react-router-dom"
import { getCookie, setCookie, deleteCookie } from "../../shared/helpers/cookie-helper"
import { getLogo } from "../../shared/helpers/logo"
import { login } from "../../shared/helpers/login"
import styles from "./main.module.css"

const { Option } = Select

export const Main = () => {
	const { domain } = useParams()
	const DEFAULT_FILTERS = { from: 0, size: 25, domain, materialName: "" }
	const [gridData, setGridData] = useState([])
	const [loading, setLoading] = useState(false)
	const [mainBlockLoading, setMainBlockLoading] = useState(true)
	const [showClearButton, setShowClearButton] = useState(false)
	const [searchValue, setSearchValue] = useState("")
	const [filters, setFilters] = useState(DEFAULT_FILTERS)
	const [showData, setShowData] = useState(false)
	const [vendors, setVendors] = useState([])
	const [searchVendorName, setSearchVendorName] = useState("")
	const [autocompleteTimeOut, setAutocompleteTimeOut] = useState(null)

	const showOwn = () => {
		setShowData(true)
		filterChange(DEFAULT_FILTERS)
	}

	const clearFilters = () => {
		setFilters(DEFAULT_FILTERS)
	}

	const checkLogin = async () => {
		if (getCookie("OutbackDomain") !== domain) {
			const res = await login(domain)
			if (res.status === 200) {
				setCookie("OutbackDomain", domain, 86400)
				await getLogo(domain)
				setMainBlockLoading(false)
				return showOwn()
			} else {
				deleteCookie("OutbackDomain")
				deleteCookie("Logo")
				routerHistory.push("/login")
				return false
			}
		} else {
			setMainBlockLoading(false)
			return showOwn()
		}
	}

	useEffect(() => { checkLogin() }, [])

	const getData = async () => {
		const response = await fetch(`${process.env.REACT_APP_OUTBACK_API_URL}/${process.env.REACT_APP_STAGE}/search`, {
			method: "POST",
			headers: { "Content-Type": "application/json", "OutbackDomain": domain },
			body: JSON.stringify(filters)
		})
		const data = await response.json()
		const currentPage = filters.from / filters.size + 1
		setGridData({ data: data.items, totalResults: data.totalResults, currentPage })
		setLoading(false)
	}

	useEffect(() => {
		if (showData) {
			setLoading(true)
			getData()
		}
	}, [filters])

	const filterChange = (filter) => {
		if (!showData && (filters.materialName || filter.materialName)) {
			setShowData(true)
		}
		setFilters({ ...filters, ...filter })
	}

	const search = () => {
		if (searchValue) {
			filterChange({ materialName: searchValue, from: 0 })
			setShowClearButton(true)
		}
	}

	const clear = () => {
		setSearchValue("")
		setShowClearButton(false)
		showOwn()
	}

	const vendorAutocomplete = async (value) => {
		const response = await fetch(`${process.env.REACT_APP_OUTBACK_API_URL}/${process.env.REACT_APP_STAGE}/autocomplete`, {
			method: "POST",
			headers: { "Content-Type": "application/json", "OutbackDomain": domain },
			body: JSON.stringify({ vendorName: value, languages: filters.languages, countries: filters.countries })
		})
		const data = await response.json()
		setVendors(data.map(item => ({ value: item })))
	}

	const searchVendorNameChange = (value) => {
		setSearchVendorName(value)

		if (value.length) {
			if (!filters.vendorName || filters.vendorName !== value) {
				if (autocompleteTimeOut != null) {
					clearTimeout(autocompleteTimeOut)
				}

				const timeOut = setTimeout(() => {
					vendorAutocomplete(value)
				}, 350)
				setAutocompleteTimeOut(timeOut)
			}
		} else {
			filterChange({ vendorName: value })
		}
	}

	return (
		mainBlockLoading ?
			<div className={`${mainBlockLoading ? "custom-loader-spinner-type mainspace" : "mainspace"}`} />
			:
			<div className="maincontainer">
				<Header clearFilters={clearFilters}/>
				<div className={styles.search_block}>
					<div className={styles.search_field}>
						<div className={`${styles.search_field_inner} ${showClearButton ? styles.search_process : ""}`}>
							<input
								type="text"
								placeholder="Material Name or CAS Number"
								value={searchValue}
								onChange={e => {
									setSearchValue(e.target.value)
									setShowClearButton(false)
								}}
								onKeyPress={e => {
									if (e.key === "Enter" && e.target.value) {
										filterChange({ materialName: e.target.value, from: 0 })
										setShowClearButton(true)
									}
								}}
							/>
							<button type="button" onClick={search}>
								<SearchMagnifier />
							</button>
							<button
								className={styles.clear_btn}
								type="button"
								onClick={clear}
							>
								<Cross />
							</button>
						</div>
						<button type="button" className={styles.show_own_link} onClick={showOwn}>Show Own</button>
					</div>
				</div>
				<div className={styles.country_language_selectors}>
					<div className={styles.country_language_selectors_inner}>
						<Select
							mode="multiple"
							placeholder={"Select Countries"}
							onChange={(e) => filterChange({ countries: e, from: 0 })}
							value={filters.countries}
							optionLabelProp="label"
							filterOption={
								(inputValue, { label }) => label.toLowerCase().includes(inputValue.toLowerCase())
							}
						>
							{COUNTRIES.map(conuntry => (
								<Option
									value={conuntry.code}
									label={conuntry.name}
									key={conuntry.code}
								>
									{conuntry.name}
								</Option>
							))}
						</Select>
						<Select
							mode="multiple"
							placeholder={"Select Languages"}
							onChange={(e) => filterChange({ languages: e, from: 0 })}
							value={filters.languages}
							optionLabelProp="label"
							filterOption={
								(inputValue, { label }) => label.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(inputValue.toLowerCase())
							}
						>
							{LANGUAGES.map(language => (
								<Option
									value={language.code}
									label={language.name}
									key={language.code}
								>
									{language.name}
								</Option>
							))}
						</Select>
						<AutoComplete
							className="vendor-autocomplete"
							options={vendors}
							value={searchVendorName}
							onChange={searchVendorNameChange}
							onSelect={(value) => filterChange({ vendorName: value, from: 0 })}
							placeholder={"Vendor Name"}
							onKeyDown={({ key }) => {
								if (key === "Enter" && searchVendorName) {
									filterChange({ vendorName: searchVendorName })
								}
							}}
						/>
					</div>
				</div>
				<div className={`outback-main-grid ${loading ? "custom-loader-spinner-type" : ""}`}>
					<Grid data={gridData} onChange={filterChange} setLoading={setLoading} />
				</div>
				<Footer />
			</div>
	)
}